const Dropzone = require("dropzone")

$(function(){
   
    if($("#drophere").length > 0) {
        
        let csrfToken = $('meta[name="csrf-token"]').attr('content')
        let uniqueSecret = $('input[name="uniqueSecret"]').attr('value')
       
        let myDropzone = new Dropzone('#drophere', {
            url: '/work/images/upload',
            params : {
                _token: csrfToken,
                uniqueSecret: uniqueSecret
            },

            addRemoveLinks: true,


            init: () => {
                $.ajax({
                    type: 'GET',
                    url: "/work/images",
                    data: {
                        uniqueSecret: uniqueSecret
                    },
                    dataType: 'json'
                }).done((data) => {
                    $.each(data, (key, value) => {
                        let file = {
                            serverId: value.id
                        }

                        myDropzone.options.addedfile.call(myDropzone, file)
                        myDropzone.options.thumbnail.call(myDropzone, file, value.src)
                    })
                })
            }
        })



        myDropzone.on("success", (file , response) => {
            file.serverId = response.id
        })

        myDropzone.on("removedfile", (file) => {
            $.ajax({
                type: 'DELETE',
                url: '/work/images/remove',
                data: {
                    _token: csrfToken,
                    id: file.serverId,
                    uniqueSecret: uniqueSecret
                },
                dataType: 'json'
            })
        })
    }






})
