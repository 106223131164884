document.addEventListener('DOMContentLoaded', () => {
    let navbar = document.querySelector('#navbar');

    window.addEventListener('scroll' , () => {
        if(window.scrollY > 100){
            navbar.classList.remove('bg-transparent')
            navbar.classList.add('bg-navbar')
        } else {
            navbar.classList.remove('bg-navbar')
            navbar.classList.add('bg-transparent')


        }
    })

    let menuClickers = document.querySelectorAll('.menuclick')

    menuClickers.forEach( el => {
        el.addEventListener('click' ,() => {
                 document.getElementById("burger").classList.toggle('is-open');
                document.getElementById("main-nav").classList.toggle('is-open');
        })
    })
    console.log(menuClickers)

    // function menuClicked(){
    //   document.getElementById("burger").classList.toggle('is-open');
    //   document.getElementById("main-nav").classList.toggle('is-open');
    // }
})
